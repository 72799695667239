<template lang="pug">
div(class="d-flex flex-column")
    ChatMsg(
        v-for="(msg, index) in list"
        :key="index"
        :msg="msg"
        :own="msg.own"
    )
</template>

<script>
import ChatMsg from "@/components/molecules/messenger/ChatMsg"
export default {
    components: {
        ChatMsg
    },
    props: {
        list: {
            type: Array
        }
    }
}
</script>