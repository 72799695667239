<template lang="pug">
IonCard(
    class="chat-msg"
    :color="own ? 'secondary' : 'quaternary'"
    :class="own ? 'own radius-left' : 'radius-right'"
)
    //- :color="own ? 'red' : 'green'"
    div {{ msg.text }}
    div(class="d-flex justify-end mt-2 align-center")
        h4 {{ msg.time }}
        img(
            style="width: 12px; height: 12px"
            src="@/assets/icon/check-white.png"
            v-if="own"
            class="ml-2"
        )
</template>

<script>
export default {
    props: {
        msg: { type: Object },
        own: { type: Boolean }
    }
}
</script>

<style lang="sass" scoped>
.chat-msg
    width: 70%
    align-self: flex-start
    padding: 10px
    margin: 5px 0
    &.own
        align-self: flex-end
        // text-align: end
</style>
