<template lang="pug">
BaseLayout
    ChatModule
</template>

<script>
import ChatModule from "@/components/molecules/messenger/ChatModule"

import { useUtilities } from "@/composables/utilities"

export default {
    components: {
        ChatModule
    },

    setup() {
        const { currentChatPerson } = useUtilities()
        return { currentChatPerson }
    }
}
</script>