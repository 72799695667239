<template lang="pug">
div
    //- name bar
    TopBar
        IonText(
            color="secondary"
        )
            h1 Messenger

    //- person
    Container
        Spacer
        IonText(
            color="secondary"
        )
            h3 Max Mustermann

    //- div
    div
        Spacer
        ChatMsgList(
            :list="messages"
        )
</template>

<script>
import TopBar from "@/components/sections/TopBar"
import ChatMsgList from "@/components/molecules/messenger/ChatMsgList"
import { ref } from "vue"
export default {
    components: { TopBar, ChatMsgList },
    setup() {
        const messages = ref([
            {
                text: "Guten Morgen",
                own: true,
                time: "13:30"
            },
            {
                text: "Hallo mein Freund",
                own: false,
                time: "13:45"
            },
            {
                text: "Bitte den Auftrag 4/11 abbrechen",
                own: true,
                time: "14:10"
            }
        ])
        return {
            messages
        }
    }
}
</script>